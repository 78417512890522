/* You can add global styles to this file, and also import other style files */
// @import '@siemens/ix/dist/siemens-ix/siemens-ix.css';

// Load only the core parts

// @import '@siemens/ix/dist/siemens-ix/siemens-ix-core.css';
@import '@siemens/ix/dist/siemens-ix/siemens-ix.css';


// Load theme
@import '@siemens/ix/dist/siemens-ix/theme/classic-light.css';
@import '@siemens/ix/dist/siemens-ix/theme/classic-dark.css';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@import '@siemens/ix-aggrid/dist/ix-aggrid/ix-aggrid.css';



@import './dark-theme.scss';

html,
body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.app-root {
  display: block;
  height: 100%;
}

$h-48: 48px;
$h-40: 40px;
$w-100percent: 100%;
$h-18: 18px;
$h-24: 24px;
$h-32: 32px;

$height-header: 44px;
$dashboard-width: 3.25rem;
$tree-width: 300px;

$padding-global: 10px;

$background-color-form: #23233C;

$dashboard-width: 3.25rem;
$tree-width: 300px;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

select {
  border-color: red !important;
  color: var(--theme-color-std-text) !important;
  background-color: var(--theme-input--background) !important;
}

app-user-org-management {
  height: 100%;
}

.pie {
  height: 250px;
}

.ag {
  height: 26rem;
  width: 100%;
}

@media screen and (max-width: 1600px) and (min-width: 501px) {
  .pie {
    height: 200px;
  }

  .ag {
    height: 22rem;

  }
}

.firstSection {
  display: flex;
  flex-direction: row;
  flex-grow: initial;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 40%;
}

.firstSubSection {
  width: 35%;
}

.secondSubSection {
  width: 65%;
}

.secondSection {
  height: 60%;
}

$Background-color-1 : #000028;
$Background-color-2 : #23233C;
$Background-color-8 : #DEDEE3;

.light-theme {
  background: #F3F3F0 0% 0% no-repeat padding-box;
}

.dark-theme {
  background: $background-color-form 0% 0% no-repeat padding-box;
}


mat-sidenav-container .mat-sidenav-content {
  overflow: hidden;
  overflow: hidden;
}